import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Tarjeta from './componentes/Tarjeta';
// import Banner from './componentes/Banner';
import NavbarComp from './componentes/NavbarComp';

// inicio Javier
import './stylesheets/Accesorios.css';
import Accesorios from './componentes/Accesorios';
import FooterFinal from './componentes/FooterFinal';
import CarouselFinal from './componentes/CarouselFinal';
// fin Javier


function App() {
  return (
    <div className="App">
      <div className='banner-cuotas'>3 y 6 cuotas sin interés. Envíos gratis a todo el país</div>

      <div className="container">
        <NavbarComp />
      </div>

      {/* <Banner/> */}
      <div className='NuestrasOfertas'>
        {/* De manera provisoria separé el título en dos h2 para poder usar colores distintos en "Nuestras" y "Ofertas". Falta chequear si hay una forma mejor de hacerlo. */}
        <div className='titulo-contenedor'>
          {/* <h2 className='nuestras'>Nuestras</h2>
          <h2 className='ofertas'>Ofertas</h2> */}
          <h2 className='nuestras'>Nuestras <span>Ofertas</span></h2>
        </div>
        <div className='tarjetas'>
          <Tarjeta
            imagen='02'
            combo='Informal'
            precio='19.99'
            precioanterior='24.99' />
          <Tarjeta
            imagen='03'
            combo='Casual'
            precio='35.99'
            precioanterior='44.99' />
          <Tarjeta
            imagen='04'
            combo='Verano'
            precio='17.99'
            precioanterior='20.99' />
          <Tarjeta
            imagen='05'
            combo='Primavera'
            precio='24.99'
            precioanterior='29.99' />

        </div>
      </div>

      {/* inicio Javier */}

      <Accesorios
        titulo1='Accesorios'
        titulo2='de Cuero'
        texto1='Tiene familiares o amigos trabajando fuerte desde casa? Nuestra caja "Home Work" 
                de snacks y bebidas es la forma perfecta de regalarles una sonrisa'
        texto2='¡y lo enviamos directo a su puerta!'
        texto3='Si desea enviar cantidades mayores contáctenos para arreglar los detalles.'
      />

      <CarouselFinal />

      <FooterFinal />

      {/* fin Javier */}

    </div>
  );
}

export default App;